/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import Workbook from 'react-excel-workbook'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import CommentBubble from "../../../assets/svgs/CommentBubble";
import CloudSaving from "../../../assets/svgs/CloudSaving";
import CloudSaved from "../../../assets/svgs/CloudSaved";

import {
  getProjectTitle,
  getProjects,
  getProjectData,
  getExportData,
  getFetchStatus,
  getPdfStatus,
  getProjectStatus,
  getFetchAllStatus,
} from "../../../store/project/projectSelectors";
import { getUserData } from "../../../store/auth/authSelectors";
import {
  setAndSaveProjectData,
  createProject,
  setCurrentProject,
  deleteProject,
  fetchAllProjects,
  setPdfStatus,
} from "../../../store/project/projectActions";
import { logout } from "../../../store/auth/authActions";
import { getMemberRole } from "../../../utils/helpers";
import WarningModal from "../../common/warning/WarningModal";

import "./Header.scss";

import { toggleNotes } from "../../../store/project/projectActions";
import Projects from "../../common/Projects";

let timeout;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectTitle: props.projectTitle,
      isModalOpen: false,
      deleteId: null,
      isHelpDropdownOpen: false,
      isInfoShown: false,
      isUserDropdownOpen: false,
      exportLoading: { isLoading: false, isSuccess: false }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectTitle !== this.props.projectTitle) {
      this.setState({
        projectTitle: this.props.projectTitle,
      });
    }

    if (
      prevProps.projectStatus !== this.props.projectStatus &&
      this.props.projectStatus === "SAVED"
    ) {
      this.toggleInfo();
    }
  }

  openModal = (id) => {
    this.setState({
      isModalOpen: true,
      deleteId: id,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      deleteId: null,
    });
  };

  onInputChange = ({ target: { value } }) => {
    this.setState({
      projectTitle: value,
    });
  };

  handleUpdateProjectTitle = () => {
    const { onSetAndSaveProjectData, currentProject } = this.props;
    const { projectTitle } = this.state;

    if (currentProject.title !== projectTitle) {
      onSetAndSaveProjectData("title", projectTitle);
    }
  };

  handleSubmitFromKeyboard = ({ key, target }) => {
    if (key === "Enter") {
      target.blur();
    }
  };

  handleCreateProject = () => {
    const { onCreateProject } = this.props;

    onCreateProject();
  };

  handleDeleteProject = (id) => {
    const { onDeleteProject } = this.props;
    this.closeModal();
    onDeleteProject(id);
  };

  handleDropdownClick = (project) => {
    const { onSetCurrentProject } = this.props;

    this.setState({
      currentProject: project,
    });

    onSetCurrentProject(project);
  };

  onPrint = () => {
    // const { onSetPdfStatus } = this.props
    document.getElementById("download").click();

    // onSetPdfStatus('WORKING')
  };

  toggleDropdown = () => {
    this.setState({
      isHelpDropdownOpen: !this.state.isHelpDropdownOpen,
    });
  };

  toggleUserDropdown = () => {
    this.setState({
      isUserDropdownOpen: !this.state.isUserDropdownOpen,
    });
  };

  handleOpenNotes = () => {
    const { onToggleNotes } = this.props;
    onToggleNotes(false);
  };

  toggleInfo = () => {
    clearTimeout(timeout);

    this.setState({
      isInfoShown: true,
    });

    timeout = setTimeout(() => {
      this.setState({
        isInfoShown: false,
      });
    }, 2000);
  };

  render() {
    const {
      user,
      logout,
      projects,
      currentProject,
      pdfStatus,
      onSetCurrentProject,
      onCreateProject,
      msgClose,
      setMsgClose,
      share,
      setShare,
      members,
      setMembers,
      projectStatus,
      onSetAndSaveProjectData,
      onDeleteProject,
    } = this.props;
    const {
      deleteId,
      isModalOpen,
      isHelpDropdownOpen,
      isUserDropdownOpen,
    } = this.state;

    const canView =
      getMemberRole(this.props.currentProject, this.props.user.user.email) ===
      "canView";
    const canEdit =
      getMemberRole(this.props.currentProject, this.props.user.user.email) ===
      "canEdit";
    const commentCount = currentProject?.comments?.length || 0;

    const specialUsersListString = process.env.REACT_APP_CSV_SPECIAL_USERS_LIST || "";
    const usersList = specialUsersListString.split(",").map(email => email.trim());

    return (
      <div className="header print-hide" style={{ whiteSpace: `nowrap` }}>
        <div className="row">
          <div className="head-flex-bar">
            <div className="head-flex-item-left">
              {isModalOpen && (
                <WarningModal
                  id={deleteId}
                  type="project"
                  onOkClick={this.handleDeleteProject}
                  onCancelClick={this.closeModal}
                >
                  Are you sure you want to delete this project?
                </WarningModal>
              )}

              <Projects
                user={user}
                projects={projects}
                currentProject={currentProject}
                setCurrent={onSetCurrentProject}
                createProject={onCreateProject}
                updateProject={onSetAndSaveProjectData}
                deleteProject={onDeleteProject}
                readonly={canView}
              />
              <div className="empty-box"></div>
              <span className="line"></span>

              {canView === false && canEdit === false && (
                <>
                  <div className="empty-box"></div>
                  <div
                    className="header-circle"
                    onClick={() => setMembers(!members)}
                  >
                    <div className="header-circle__number">
                      {currentProject?.members?.length || 0}
                    </div>
                  </div>
                </>
              )}

              <div className="empty-box"></div>
              <button
                className={`shareBtn ${canView || canEdit ? "readonly" : ""}`}
                onClick={() => setShare(!share)}
              >
                Share
              </button>
              <div className="empty-box"></div>
              <span className="line"></span>

              <div
                className="comment-wrapper"
                onClick={() => setMsgClose(!msgClose)}
              >
                <div className="comment-icon">
                  <CommentBubble />
                  {commentCount ? <div className="comment-dot" /> : null}
                </div>
              </div>

              <span className="line" />

              <div
                className="save-status"
                data-saved={projectStatus === "SAVED"}
              >
                <div className="save-icon">
                  {projectStatus === "SAVED" ? <CloudSaved /> : <CloudSaving />}
                </div>

                <div className="save-text">
                  {projectStatus === "SAVED" ? "Saved" : "Saving"}
                </div>
              </div>
            </div>
            <div className="head-flex-item-right">
              <div className="flex-justify-righ">
                <Dropdown
                  isOpen={isHelpDropdownOpen}
                  toggle={() => this.toggleDropdown()}
                >
                  <DropdownToggle
                    color="none"
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "black",
                      padding: "0"
                    }}
                  >
                    <a className="font">Need help?</a>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Link to="/video">Intro Video</Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to="/help" className="link">
                        FAQ
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to="/gpt-feature" className="link">
                        GPT Feature
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        href="assets/examples/flyability_example.pdf"
                        className="new-btn btn-example"
                        target="_blank"
                      >
                        Example
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        href="https://form.jotform.com/Where_play/bug-reporting---wheretoplay-webapp"
                        target="_blank" rel="noreferrer"
                      >
                        Bug report
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {user &&
                  (usersList.includes(user.user.email)) && (
                    <div className="export">
                      <Link to="/download-projects" className="new-btn exportbtn btn-download" rel="noopener noreferrer">
                        Download file
                      </Link>
                    </div>
                  )}

                {navigator.userAgent.indexOf("Chrome") >= 0 && (
                  <a
                    className="new-btn btn-download pl-sm"
                    onClick={this.onPrint}
                  >
                    {pdfStatus === "WORKING" ? (
                      <Spinner color="dark" />
                    ) : (
                      "Download PDF"
                    )}
                  </a>
                )}

                {user ? (
                  <Dropdown
                    isOpen={isUserDropdownOpen}
                    toggle={() => this.toggleUserDropdown()}
                  >
                    <DropdownToggle
                      color="none"
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        color: "black",
                      }}
                    >
                      <div className="user-profile">
                        <div className="user-avatar">
                          <span>{user.user.username[0]}</span>
                        </div>
                        <div className="user-email">{user.user.email}</div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link
                        className="dropdown-item"
                        style={{ textTransform: "none" }}
                        to="/login"
                        onClick={logout}
                      >
                        Logout
                      </Link>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <Link to="/login" className="new-btn ml32">
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  projectTitle: getProjectTitle(state),
  user: getUserData(state),
  projects: getProjects(state),
  exportData: getExportData(state),
  currentProject: getProjectData(state),
  fetchStatus: getFetchStatus(state),
  pdfStatus: getPdfStatus(state),
  projectStatus: getProjectStatus(state),
  fetchAllStatus: getFetchAllStatus(state),
});

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) =>
    dispatch(setAndSaveProjectData(name, value)),
  logout: () => dispatch(logout()),
  onCreateProject: () => dispatch(createProject()),
  onSetCurrentProject: (project) => dispatch(setCurrentProject(project)),
  onDeleteProject: (id) => dispatch(deleteProject(id)),
  onFetchAllProjects: () => dispatch(fetchAllProjects()),
  onSetPdfStatus: (status) => dispatch(setPdfStatus(status)),
  onToggleNotes: (visibility) => dispatch(toggleNotes(visibility)),
});

export default withRouter(connect(mapState, mapDispatch)(Header));
