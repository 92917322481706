import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  defaultProject,
  defaultChat,
  chatWelcomeMsg,
  defaultChatSession,
} from "../../utils/constants";
import {
  checkProjectIds,
  getProjectChatIndex,
  getProjectsChat,
  getProjectsChatSession,
  getTotalNonEmptyAbilitiesCount,
} from "../../utils/helpers";

import {
  PROJECT_DATA_FETCH_START,
  PROJECT_DATA_FETCH_FAIL,
  PROJECT_DATA_FETCH_SUCCESS,
  ALL_PROJECTS_FETCH_START,
  ALL_PROJECTS_FETCH_SUCCESS,
  ALL_PROJECTS_FETCH_FAIL,
  PROJECT_DATA_UPDATE_START,
  PROJECT_DATA_UPDATE_SUCCESS,
  PROJECT_DATA_UPDATE_FAIL,
  PROJECT_CREATE_START,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAIL,
  CURRENT_PROJECT_SET,
  PROJECT_DELETE_START,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAIL,
  PROJECT_DELETE_ALL,
  CURRENT_OPPORTUNITY_SET,
  UPDATE_STATUS_SET,
  TOGGLE_NOTES,
  PDF_STATUS_SET,
  PROJECT_STATUS_SET,
  SAVE_STATUS_SET,
  CHANGE_OPPORTUNITY_SET,
  ADD_MEMBER_SUCCESS,
  PROJECT_COUNT_SET,
  CLEAR_EXPORT_DATA,
} from "./projectActions";

import {
  SET_CHAT_FIELD,
  SET_INITIAL_CHAT,
  SET_INITIAL_SESSION_CHAT,
  SET_CHAT_SESSION_FIELD,
  PROJECT_DELETE_CHATS_AND_SESSION,
  PROJECT_GENERATE_CHATS_AND_SESSION,
} from "./chatActions";

import { getAllSetOpportunities } from "./projectSelectors";
import { tokenLimitExceededWelcomeMsg } from "../../components/chatbot/chat-utils";

const initialState = {
  projects: [],
  currentProject: defaultProject,
  fetchStatus: "UNTOUCHED",
  updateStatus: "UNTOUCHED",
  createStatus: "UNTOUCHED",
  deleteStatus: "UNTOUCHED",
  setStatus: "UNTOUCHED",
  fetchAllStatus: "UNTOUCHED",
  currentAttractivenessOpportunityId: null,
  notesVisibility: true,
  exportData: null,
  pdfStatus: "UNTOUCHED",
  projectStatus: "SAVED",
  saveStatus: "SAVED",
  projectsCount: 0,
  chatSessions: [],
  projectChats: [],
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_DATA_FETCH_START: {
      return {
        ...state,
        fetchStatus: "WORKING",
      };
    }

    case PROJECT_DATA_FETCH_SUCCESS: {
      const projects = action.projects;
      const currentProjectId = state.currentProject.id;
      const updatedProjects = projects.map((project) =>
        checkProjectIds(project)
      );
      const currentProjectObject = updatedProjects.find(
        (project) => project.id === currentProjectId
      );

      return {
        ...state,
        projects: updatedProjects,
        currentProject: currentProjectObject
          ? currentProjectObject
          : updatedProjects[0],
        fetchStatus: "SUCCESS",
      };
    }

    case PROJECT_DATA_FETCH_FAIL: {
      return {
        ...state,
        fetchStatus: "ERROR",
      };
    }

    case ALL_PROJECTS_FETCH_START: {
      return {
        ...state,
        fetchAllStatus: "WORKING",
      };
    }

    case ALL_PROJECTS_FETCH_SUCCESS: {
      return {
        ...state,
        exportData: action.exportData,
        fetchAllStatus: "SUCCESS",
      };
    }

    case ALL_PROJECTS_FETCH_FAIL: {
      return {
        ...state,
        fetchAllStatus: "ERROR",
      };
    }

    case PROJECT_DATA_UPDATE_START: {
      const updatedProjectId = state.projects.findIndex(
        (x) => x.id === action.projectData.id
      );

      state.projects[updatedProjectId] = action.projectData;

      return {
        ...state,
        currentProject: action.projectData,
        updateStatus: "WORKING",
      };
    }

    case PROJECT_DATA_UPDATE_SUCCESS: {
      const saveStatus = state.saveStatus;
      const updatedProjectId = state.projects.findIndex(
        (x) => x.id === action.projectData.id
      );

      state.projects[updatedProjectId] = action.projectData;

      if (saveStatus === "UNSAVED") {
        return {
          ...state,
          updateStatus: "SUCCESS",
        };
      } else {
        return {
          ...state,
          updateStatus: "SUCCESS",
        };
      }
    }

    case PROJECT_DATA_UPDATE_FAIL:
      return {
        ...state,
        updateStatus: "ERROR",
      };

    case PROJECT_CREATE_START: {
      return {
        ...state,
        createStatus: "WORKING",
      };
    }

    case PROJECT_CREATE_SUCCESS: {
      state.projects.push(action.project);

      const currentProject = action.project;

      const currentProjectChatSession = {
        ...defaultChatSession,
        projectId: currentProject.id,
      };

      const currentProjectChat = {
        ...defaultChat,
        projectId: currentProject.id,
        chats: [chatWelcomeMsg],
      };

      const chatSessions = [...state.chatSessions, currentProjectChatSession];
      const projectChats = [...state.projectChats, currentProjectChat];

      return {
        ...state,
        currentProject: action.project,
        chatSessions,
        projectChats,
        createStatus: "SUCCESS",
      };
    }

    case PROJECT_CREATE_FAIL: {
      return {
        ...state,
        createStatus: "ERROR",
      };
    }

    case CURRENT_PROJECT_SET: {
      if (state.deleteStatus !== "WORKING") {
        return {
          ...state,
          currentProject: action.project,
          setStatus: "SUCCESS",
        };
      } else {
        return {
          ...state,
          setStatus: "SUCCESS",
        };
      }
    }

    case PROJECT_DELETE_START: {
      return {
        ...state,
        deleteStatus: "WORKING",
      };
    }

    case PROJECT_DELETE_SUCCESS: {
      const projectId = state.projects.findIndex(
        (project) => project.id === action.id
      );

      state.projects.splice(projectId, 1);

      if (state.currentProject.id === action.id) {
        return {
          ...state,
          currentProject: state.projects[state.projects.length - 1],
          deleteStatus: "SUCCESS",
        };
      } else {
        return {
          ...state,
          deleteStatus: "SUCCESS",
        };
      }
    }

    case PROJECT_DELETE_FAIL: {
      return {
        ...state,
        deleteStatus: "ERROR",
      };
    }
    case PROJECT_DELETE_ALL: {
      return initialState;
    }

    case CURRENT_OPPORTUNITY_SET: {
      if (action.name === "primary") {
        return {
          ...state,
          currentAgileFocusPrimaryOpportunityId: action.id,
        };
      } else if (action.name === "attractiveness") {
        return {
          ...state,
          currentAttractivenessOpportunityId: action.id,
        };
      } else {
        return {
          ...state,
          currentAgileFocusOpportunityIds: { ...action.id },
        };
      }
    }

    case UPDATE_STATUS_SET: {
      return {
        ...state,
        updateStatus: action.status,
      };
    }

    case PROJECT_STATUS_SET: {
      return {
        ...state,
        projectStatus: action.status,
      };
    }

    case SAVE_STATUS_SET: {
      return {
        ...state,
        saveStatus: action.status,
      };
    }

    case TOGGLE_NOTES: {
      return {
        ...state,
        notesVisibility: action.visibility,
      };
    }

    case PDF_STATUS_SET: {
      return {
        ...state,
        pdfStatus: action.status,
      };
    }

    case ADD_MEMBER_SUCCESS: {
      const updatedProjectId = state.projects.findIndex(
        (x) => x.id === action.projectData.id
      );

      state.projects[updatedProjectId] = action.projectData;

      return {
        ...state,
        currentProject: action.projectData,
      };
    }

    case PROJECT_COUNT_SET: {
      return {
        ...state,
        projectsCount: action.count,
      };
    }

    case PROJECT_GENERATE_CHATS_AND_SESSION: {
      const projects = state.projects;
      const projectChats = getProjectsChat(projects);
      const chatSessions = getProjectsChatSession(state.chatSessions, projects);

      return {
        ...state,
        chatSessions: [...chatSessions],
        projectChats: [...projectChats],
      };
    }

    case PROJECT_DELETE_CHATS_AND_SESSION: {
      const projectId = action.projectId;
      const chatSessions = [...state.chatSessions];
      const projectChats = [...state.projectChats];

      const deletedProjectChatSessionIndex = getProjectChatIndex(
        projectId,
        chatSessions
      );

      const deletedProjectChatIndex = getProjectChatIndex(
        projectId,
        projectChats
      );

      // DELETE CHAT_SESSION_OBJECT OF THE DELETED PROJECT
      if (deletedProjectChatSessionIndex !== -1) {
        chatSessions.splice(deletedProjectChatSessionIndex, 1);
      }

      // DELETE CHAT_SESSION_OBJECT OF THE DELETED PROJECT
      if (deletedProjectChatIndex !== -1) {
        projectChats.splice(deletedProjectChatIndex, 1);
      }

      return {
        ...state,
        chatSessions,
        projectChats,
      };
    }

    case SET_CHAT_SESSION_FIELD:
      const { field, value, projectId } = action.payload;
      const allProjectsChatSession = [...state.chatSessions];

      const currentChatSessionIndex = getProjectChatIndex(
        projectId,
        allProjectsChatSession
      );

      // UPDATE SPECIFIC FIELDS OF A SPECIFIC CHAT_SESSION_OBJECT BASED ON THE PROJECT_ID IN THE CHAT_SESSIONS ARRAY
      if (currentChatSessionIndex !== -1) {
        allProjectsChatSession[currentChatSessionIndex] = {
          ...allProjectsChatSession[currentChatSessionIndex],
          [field]: value,
        };
      }

      return {
        ...state,
        chatSessions: [...allProjectsChatSession],
      };

    case SET_CHAT_FIELD:
      const {
        field: chatField,
        value: chatFieldValue,
        projectId: chatProjectId,
      } = action.payload;

      const allProjectsChat = [...state.projectChats];

      const currentChatIndex = getProjectChatIndex(
        chatProjectId,
        allProjectsChat
      );

      // UPDATE SPECIFIC FIELDS OF A SPECIFIC CHAT_SESSION_OBJECT BASED ON THE PROJECT_ID IN THE CHAT_SESSIONS ARRAY
      if (currentChatIndex !== -1) {
        allProjectsChat[currentChatIndex] = {
          ...allProjectsChat[currentChatIndex],
          [chatField]: chatFieldValue,
        };
      }

      return {
        ...state,
        projectChats: [...allProjectsChat],
      };

    case SET_INITIAL_CHAT:
      const currentProject = action.payload;
      const projectChats = [...state.projectChats];
      const totalChats = projectChats.length;

      const newChat = {
        ...defaultChat,
        projectId: currentProject.id,
        usedGptTokens: currentProject?.usedGptTokens ?? 0,
        chats:
          currentProject.chats && currentProject.chats.length
            ? [...currentProject.chats, chatWelcomeMsg]
            : [chatWelcomeMsg],
      };

      if (totalChats > 0) {
        projectChats[totalChats] = newChat;
      } else {
        projectChats[0] = newChat;
      }

      return {
        ...state,
        projectChats,
      };

    case SET_INITIAL_SESSION_CHAT:
      const project = action.payload;
      const chatSessions = [...state.chatSessions];
      const totalChatSessions = chatSessions.length;

      const isChatOpenerActive = getTotalNonEmptyAbilitiesCount({ project })
        ? true
        : false;

      const newChatSession = {
        ...defaultChatSession,
        projectId: project.id,
        isChatOpenerActive,
        ...tokenLimitExceededWelcomeMsg({ project }),
      };

      if (totalChatSessions > 0) {
        chatSessions[totalChatSessions] = newChatSession;
      } else {
        chatSessions[0] = newChatSession;
      }

      return {
        ...state,
        chatSessions,
      };

    case CLEAR_EXPORT_DATA:
      return { ...state, exportData: null };

    default:
      break;
  }

  return state;
};

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: "project",
  storage,
  blacklist: [
    "fetchStatus",
    "updateStatus",
    "createStatus",
    "deleteStatus",
    "setStatus",
    "exportData",
    "fetchAllStatus",
    "chatSessions",
  ],
};

const persistableReducer: (CartState, CartAction) => CartState = persistReducer(
  persistConfig,
  projectReducer
);

export default persistableReducer;
