import React, { Component } from "react";
import { connect } from "react-redux";

import WorksheetPage from "./WorksheetPage";
import AbilitySection from "../../common/worksheet-1/AbilitySection";
import OpportunitySection from "../../common/worksheet-1/OpportunitySection";
import TooltipItem from "../../common/worksheet-1/TooltipItem";
import {
  getProjectCustomerSegments,
  getSectionCount,
  getUpdateStatus,
  getProjectData,
  getAbilities,
  getAbilitiesSectionCount,
  getApplications,
  getAllSetOpportunities,
} from "../../../store/project/projectSelectors";
import {
  setAndSaveProjectData,
  createAndUpdateSegmentData,
  setUpdateStatus,
} from "../../../store/project/projectActions";
import { createUniqueId } from "../../../utils/helpers";
import "./WorksheetPage1.scss";
import { getMemberRole } from "../../../utils/helpers";
import { getUserData } from "../../../store/auth/authSelectors";
import {
  IS_CHAT_OPENER_ACTIVE,
  updateChatSessionField,
} from "../../../store/project/chatActions";

const MAX_OPPORTUNITIES = 8;
const MAX_SECTIONS = 6;

const scrollToHorizontalEnd = (element, debouncer, ms = 50) => {
  debouncer && clearTimeout(debouncer);

  setTimeout(() => {
    element.scrollTo({
      top: 0,
      left: element.scrollWidth,
      behavior: "smooth",
    });

    clearTimeout(debouncer);
  }, ms);
};

class WorksheetPage1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerSegments: props.customerSegments,
      isSubSegmentVisibleObject: [],
      abilities: props.abilities,
      applications: props.applications,
      isWithoutUpdate: true,
      totalNonEmptyAbilities: 0,
    };

    this.opportunityElement = React.createRef();
    this.opportunityDebounce = null;
    this.abilityElement = React.createRef();
    this.abilityDebounce = null;
  }

  getNonEmptyAbilityPropertiesCount({ properties = [] }) {
    let count = 0;

    properties.forEach((property) => {
      const propertyText = property.propertyTitle;
      if (!!propertyText.trim().length) {
        count++;
      }
    });

    return count;
  }

  getTotalNonEmptyAbilities() {
    let count = 0;

    this.props.abilities.forEach((ability) => {
      const abilityText = ability.abilityText;

      if (!!abilityText.trim().length) {
        count++;
      }

      count += this.getNonEmptyAbilityPropertiesCount({
        properties: ability.abilityProperties,
      });
    });

    return count;
  }

  updateChatOpenerStatus({ abilitiesCount }) {
    const currentProjectId = this.props.currentProject.id;

    if (abilitiesCount >= 1) {
      this.props.onUpdateChatSessionField({
        projectId: currentProjectId,
        field: IS_CHAT_OPENER_ACTIVE,
        value: true,
      });
    } else {
      this.props.onUpdateChatSessionField({
        projectId: currentProjectId,
        field: IS_CHAT_OPENER_ACTIVE,
        value: false,
      });
    }
  }

  componentDidMount() {
    let isSubSegmentVisibleObject = [];
    const totalNonEmptyAbilities = this.getTotalNonEmptyAbilities();

    this.props.customerSegments.map((segment) => {
      if (segment) {
        let isSubSegmentVisible = false;

        if (segment.opportunities && segment.opportunities.length === 0) {
          isSubSegmentVisible = true;
        }

        isSubSegmentVisibleObject.push({
          id: segment.uid,
          isSubSegmentVisible,
        });
      }
    });

    this.setState({
      isSubSegmentVisibleObject,
      totalNonEmptyAbilities,
    });

    // TRIGGER CHAT OPENER
    this.updateChatOpenerStatus({ abilitiesCount: totalNonEmptyAbilities });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customerSegments !== this.props.customerSegments ||
      prevProps.abilities !== this.props.abilities ||
      prevProps.applications !== this.props.applications
    ) {
      this.setState({
        customerSegments: this.props.customerSegments,
        abilities: this.props.abilities,
        applications: this.props.applications,
      });

      let isSubSegmentVisibleObject = [];

      this.props.customerSegments.map((segment) => {
        if (segment) {
          let isSubSegmentVisible = false;

          if (segment.opportunities.length === 0) {
            isSubSegmentVisible = true;
          }

          isSubSegmentVisibleObject.push({
            id: segment.uid,
            isSubSegmentVisible,
          });
        }
      });

      this.setState({
        isSubSegmentVisibleObject,
      });
    }

    // TRIGGER CHAT OPENER
    const totalNonEmptyAbilities = this.getTotalNonEmptyAbilities();

    if (
      prevProps.currentProject.id === this.props.currentProject.id &&
      prevState.totalNonEmptyAbilities !== totalNonEmptyAbilities
    ) {
      this.setState({
        totalNonEmptyAbilities,
      });

      this.updateChatOpenerStatus({ abilitiesCount: totalNonEmptyAbilities });
    }
  }

  toggleSubSegmentVisibility = (id, value) => {
    const { isSubSegmentVisibleObject } = this.state;

    isSubSegmentVisibleObject[
      isSubSegmentVisibleObject.findIndex((x) => x.id === id)
    ].isSubSegmentVisible = value;

    this.setState({
      isSubSegmentVisibleObject,
    });
  };

  handleNewSegment = ({ target: { value } }, sectionId) => {
    if (value !== "") {
      const { onCreateAndUpdateSegmentData } = this.props;
      const { customerSegments } = this.state;

      let newSegment = {
        sectionId,
        title: value,
        opportunities: [],
        isInList: true,
        isOnSet: false,
        uid: createUniqueId(),
        originNameId: createUniqueId(),
      };

      onCreateAndUpdateSegmentData([...customerSegments, newSegment]);
    }
  };

  handleNewOpportunity = ({ target: { value } }, id) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (segment) => segment && segment.uid === id
    );
    let newOpportunity = {
      title: value,
      isInList: true,
      isOnSet: false,
      uid: createUniqueId(),
      originNameId: createUniqueId(),
    };

    if (value !== "" && customerSegments[segmentId]) {
      customerSegments[segmentId].opportunities.push(newOpportunity);
      onCreateAndUpdateSegmentData(customerSegments);
      this.toggleSubSegmentVisibility(id, false);
    }
  };

  handleUpdateSegment = ({ target: { value } }, id) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments, isWithoutUpdate } = this.state;
    const segmentId = customerSegments.findIndex(
      (seg) => seg && seg.uid === id
    );

    if (value === "") {
      customerSegments.splice(segmentId, 1);
    } else if (customerSegments[segmentId] && !isWithoutUpdate) {
      customerSegments[segmentId].title = value;
      onCreateAndUpdateSegmentData(customerSegments);
    }

    this.setState({
      isWithoutUpdate: true,
    });
  };

  handleUpdateOpportunity = ({ target: { value } }, opptId, segId) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments, isWithoutUpdate } = this.state;
    const segmentId = customerSegments.findIndex(
      (segment) => segment && segment.uid === segId
    );
    let opportunityId = null;

    if (customerSegments[segmentId]) {
      opportunityId = customerSegments[segmentId].opportunities.findIndex(
        (oppt) => oppt && oppt.uid === opptId
      );
    }

    if (value === "" && customerSegments[segmentId]) {
      customerSegments[segmentId].opportunities.splice(opportunityId, 1);
      onCreateAndUpdateSegmentData(customerSegments);
    } else if (
      opportunityId !== null &&
      customerSegments[segmentId].opportunities[opportunityId] &&
      !isWithoutUpdate
    ) {
      customerSegments[segmentId].opportunities[opportunityId].title = value;
      onCreateAndUpdateSegmentData(customerSegments);
    }

    this.setState({
      isWithoutUpdate: true,
    });
  };

  handleDeleteOpportunity = (opptId, segId) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (segment) => segment && segment.uid === segId
    );
    let opportunityId = null;

    if (customerSegments[segmentId]) {
      opportunityId = customerSegments[segmentId].opportunities.findIndex(
        (oppt) => oppt && oppt.uid === opptId
      );

      if (opportunityId !== null) {
        customerSegments[segmentId].opportunities.splice(opportunityId, 1);
      }

      if (customerSegments[segmentId].opportunities.length !== 0) {
        this.toggleSubSegmentVisibility(segId, false);
      }
    }

    onCreateAndUpdateSegmentData(customerSegments);
  };

  handleDeleteSegment = (segId) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (segment) => segment && segment.uid === segId
    );

    customerSegments.splice(segmentId, 1);

    onCreateAndUpdateSegmentData(customerSegments);
  };

  handleSegmentChange = ({ target: { value } }, id) => {
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (seg) => seg && seg.uid === id
    );

    if (
      customerSegments[segmentId] &&
      customerSegments[segmentId].title !== value
    ) {
      customerSegments[segmentId].title = value;

      this.setState({
        customerSegments,
        isWithoutUpdate: false,
      });
    }
  };

  handleOpportunityChange = ({ target: { value } }, opptId, segId) => {
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (seg) => seg && seg.uid === segId
    );
    let opportunityId = null;

    if (customerSegments[segmentId]) {
      opportunityId = customerSegments[segmentId].opportunities.findIndex(
        (oppt) => oppt && oppt.uid === opptId
      );
    }

    if (
      opportunityId !== null &&
      customerSegments[segmentId].opportunities[opportunityId] &&
      customerSegments[segmentId].opportunities[opportunityId].title !== value
    ) {
      customerSegments[segmentId].opportunities[opportunityId].title = value;
      this.setState({
        customerSegments,
        isWithoutUpdate: false,
      });
    }
  };

  handleOpportunitySet = (opptId, segId) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (seg) => seg && seg.uid === segId
    );
    let opportunityId = null;

    if (customerSegments[segmentId]) {
      opportunityId = customerSegments[segmentId].opportunities.findIndex(
        (oppt) => oppt && oppt.uid === opptId
      );
    }

    if (
      opportunityId !== null &&
      customerSegments[segmentId].opportunities[opportunityId]
    ) {
      customerSegments[segmentId].opportunities[opportunityId].isOnSet =
        !customerSegments[segmentId].opportunities[opportunityId].isOnSet;

      this.setState({
        customerSegments,
      });

      onCreateAndUpdateSegmentData(customerSegments);
    }
  };

  handleSegmentSet = (segId) => {
    const { onCreateAndUpdateSegmentData } = this.props;
    const { customerSegments } = this.state;
    const segmentId = customerSegments.findIndex(
      (seg) => seg && seg.uid === segId
    );

    if (customerSegments[segmentId]) {
      customerSegments[segmentId].isOnSet =
        !customerSegments[segmentId].isOnSet;
      onCreateAndUpdateSegmentData(customerSegments);
    }
  };

  handleSubmitFromKeyboard = ({ key, target }) => {
    if (key === "Enter") {
      target.blur();
    }
  };

  handleAddSection = () => {
    const { onSetAndSaveProjectData, projectData } = this.props;
    const count = projectData.sectionCount;
    const shouldAdd = count < MAX_SECTIONS;

    if (shouldAdd) {
      onSetAndSaveProjectData("sectionCount", count + 1);

      scrollToHorizontalEnd(
        this.opportunityElement.current,
        this.opportunityDebounce
      );
    }
  };

  handleAddAbilitySection = () => {
    const { onSetAndSaveProjectData, projectData } = this.props;
    const count = projectData.abilitiesSectionCount;
    const shouldAdd = count < MAX_OPPORTUNITIES;

    if (shouldAdd) {
      onSetAndSaveProjectData("abilitiesSectionCount", count + 1);

      scrollToHorizontalEnd(this.abilityElement.current, this.abilityDebounce);
    }
  };

  handleDeleteSection = (sectionId) => {
    const { onSetAndSaveProjectData, projectData } = this.props;
    const { customerSegments } = this.props;
    const sectionSegments = customerSegments.filter(
      (segment) => segment && segment.sectionId === sectionId
    );

    sectionSegments.map((segment) => {
      if (segment) {
        this.handleDeleteSegment(segment.uid);
      }
    });

    onSetAndSaveProjectData("sectionCount", projectData.sectionCount - 1);
  };

  handleDeleteAbilitySection = (sectionId) => {
    const { onSetAndSaveProjectData, projectData } = this.props;
    // const { abilities } = this.props
    // const sectionAbilities = abilities.filter(ability => ability.abilitySectionId === sectionId)

    // sectionAbilities.map(segment => {
    //   this.handleDeleteAbility(segment.segmentId)
    // })

    onSetAndSaveProjectData(
      "abilitiesSectionCount",
      projectData.abilitiesSectionCount - 1
    );
  };

  handleInputChange = ({ target: { value } }, sectionId, name, inputId) => {
    const { abilities } = this.state;
    const { onSetUpdateStatus, updateStatus } = this.props;
    const abilityId = abilities.findIndex(
      (ability) => ability && ability.abilitySectionId === sectionId
    );

    if (abilityId === -1) {
      let abilityProperties = [];
      let abilityText = "";

      if (name === "properties") {
        abilityProperties.push({
          propertyTitle: value,
          propertyInputId: inputId,
          uid: createUniqueId(),
        });
      } else {
        abilityText = value;
      }

      abilities.push({
        abilityText,
        abilitySectionId: sectionId,
        abilityProperties,
        uid: createUniqueId(),
      });
    } else {
      if (name === "properties") {
        let propertyInputId = abilities[abilityId].abilityProperties.findIndex(
          (property) => property && property.propertyInputId === inputId
        );

        if (propertyInputId === -1) {
          abilities[abilityId].abilityProperties.push({
            propertyTitle: value,
            propertyInputId: inputId,
            uid: createUniqueId(),
          });
        } else {
          abilities[abilityId].abilityProperties[
            propertyInputId
          ].propertyTitle = value;
        }
      } else {
        abilities[abilityId].abilityText = value;
      }
    }

    this.setState({
      abilities,
    });

    if (updateStatus !== "SUCCESS") {
      onSetUpdateStatus("SUCCESS");
    }
  };

  handleUpdateAbilitySection = ({ target: { value } }, sectionId) => {
    const { onSetAndSaveProjectData } = this.props;
    const { abilities } = this.state;

    onSetAndSaveProjectData("abilities", abilities);
  };

  handleApplicationChange = ({ target: { value } }, sectionId) => {
    const { applications } = this.state;
    const { onSetUpdateStatus, updateStatus } = this.props;
    const applicationId = applications.findIndex(
      (app) => app && app.sectionId === sectionId
    );

    if (applicationId === -1) {
      applications.push({
        title: value,
        sectionId,
      });
    } else {
      applications[applicationId].title = value;
    }

    this.setState({
      applications,
    });

    if (updateStatus !== "SUCCESS") {
      onSetUpdateStatus("SUCCESS");
    }
  };

  handleUpdateApplication = ({ target: { value } }, sectionId) => {
    const { onSetAndSaveProjectData } = this.props;
    const { applications } = this.state;

    onSetAndSaveProjectData("applications", applications);
  };

  render() {
    const {
      sectionCount,
      abilitiesSectionCount,
      isWithoutNavigation,
      opportunities,
      pdfSectionCount,
      pdfInitialSectionCount,
      pdfAbilitiesSectionCount,
      pdfInitialAbilitiesSectionCount,
      pdf,
    } = this.props;
    const {
      customerSegments,
      isSubSegmentVisibleObject,
      abilities,
      applications,
    } = this.state;
    let opportunitySections = [];
    let abilitiesSections = [];
    let initialSectionCount = 1;
    let segmentsSectionCount = sectionCount;
    let initialAbilitiesSectionCount = 1;
    let abilitySectionCount = abilitiesSectionCount;

    if (pdfSectionCount) {
      segmentsSectionCount = pdfSectionCount;
    }

    if (pdfInitialSectionCount) {
      initialSectionCount = pdfInitialSectionCount;
    }

    if (pdfAbilitiesSectionCount) {
      abilitySectionCount = pdfAbilitiesSectionCount;
    }

    if (pdfInitialSectionCount) {
      initialAbilitiesSectionCount = pdfInitialAbilitiesSectionCount;
    }

    const readonly =
      getMemberRole(
        this.props.currentProject,
        this.props?.user?.user?.email
      ) === "canView";

    for (var i = initialSectionCount; i <= segmentsSectionCount; i++) {
      let sectionCustomerSegments = customerSegments.filter(
        (segment) => segment && segment.sectionId === i
      );
      let application = null;

      if (applications) {
        application = applications.find((app) => app && app.sectionId === i);
      }

      opportunitySections.push(
        <div key={i} className="col-sm-4 col-without-padding">
          <OpportunitySection
            key={i}
            sectionId={i}
            customerSegments={sectionCustomerSegments}
            onNewSegment={this.handleNewSegment}
            onUpdateSegment={this.handleUpdateSegment}
            onSegmentChange={this.handleSegmentChange}
            onNewOpportunity={this.handleNewOpportunity}
            onUpdateOpportunity={this.handleUpdateOpportunity}
            onOpportunityChange={this.handleOpportunityChange}
            onSubmitFromKeyboard={this.handleSubmitFromKeyboard}
            onDeleteOpportunity={this.handleDeleteOpportunity}
            onSetOpportunity={this.handleOpportunitySet}
            onSetSegment={this.handleSegmentSet}
            onDeleteSegment={this.handleDeleteSegment}
            onDeleteSection={this.handleDeleteSection}
            onToggleSubSegmentVisibility={this.toggleSubSegmentVisibility}
            isSubSegmentVisibleObject={isSubSegmentVisibleObject}
            onUpdateApplication={this.handleUpdateApplication}
            onApplicationChange={this.handleApplicationChange}
            application={application}
            readonly={readonly}
            opportunityCount={opportunities.length}
            pdf={pdf}
          />
        </div>
      );
    }

    for (var j = initialAbilitiesSectionCount; j <= abilitySectionCount; j++) {
      let sectionAbility = abilities.filter(
        (ability) => ability && ability.abilitySectionId === j
      )[0];

      abilitiesSections.push(
        <div key={j} className="col-sm-3 col-without-padding">
          <AbilitySection
            key={j}
            sectionId={j}
            sectionAbility={sectionAbility}
            onInputChange={this.handleInputChange}
            onUpdateAbilitySection={this.handleUpdateAbilitySection}
            onSubmitFromKeyboard={this.handleSubmitFromKeyboard}
            onAddSection={this.handleAddAbilitySection}
            onDeleteSection={this.handleDeleteAbilitySection}
            isLastSection={j === abilitiesSectionCount}
            readonly={readonly}
          />
        </div>
      );
    }

    return (
      <WorksheetPage
        isWithoutNavigation={isWithoutNavigation}
        link="/worksheet-1"
      >
        <div className="worksheet-page1">
          <div className="section-title">Abilities</div>
          <div className="section-title">Applications</div>
          <div className="section-title">Customers</div>
          <div className="worksheet-title ability">
            <TooltipItem typeId="market-1">
              Begin with characterizing your <b>unique abilities</b> or core
              technological elements. Describe their functions and properties,
              detached from any (envisioned) product. These will create the
              'building blocks' for different applications that you can offer.
              <br />
              <a
                href="assets/examples/flyability_example_Worksheet_1.pdf"
                className="tooltip-link"
                target="_blank"
              >
                See example
              </a>
            </TooltipItem>
            <div className="title-content">
              <div className="main-heading">
                List the venture’s core abilities or technological elements
              </div>
              <div className="sub-heading">
                Characterize them based on their functions and properties.
                Describe them in a general manner, independent from your
                (envisioned) product.
              </div>
            </div>
          </div>
          {/* Helper wrapper so the arrow can be placed
          in the middle and you can still scroll throught the container */}
          <div
            className="helper-wrapper"
            style={{ overflow: "visible", position: "relative" }}
          >
            <div
              className={`abilities-wrapper ${
                abilitiesSectionCount > 4 ? "scroll" : ""
              }`}
            >
              <div className="abilities-section">
                <div className="row ability-row" ref={this.abilityElement}>
                  {abilitiesSections}
                </div>
              </div>
              <div className="adder">
                <TooltipItem
                  type="add-section"
                  onClick={() => this.handleAddAbilitySection()}
                  typeId="abilities"
                  readonly={readonly}
                />
                <div className="counter">
                  {abilitiesSectionCount} / {MAX_OPPORTUNITIES}
                </div>
              </div>
            </div>
            <div className="arrow"></div>
          </div>
          <div className="worksheet-title identify">
            <TooltipItem typeId="market-2">
              An <b>application</b> is specific usage or function which you can
              create with your core abilities. It can be based on some or all
              the key elements listed above.
              <br />
              For each application, consider the different{" "}
              <b>customer segments</b> who may need it. Zoom in on potential
              customer segments to identify sub segments.
              <br />
              Remember: any combination of application and customers is one
              market opportunity for your business.
              <br />
              <a
                href="assets/examples/flyability_example_Worksheet_1.pdf"
                className="tooltip-link"
                target="_blank"
              >
                See example
              </a>
            </TooltipItem>
            <div className="title-content">
              <div className="main-heading">
                Identify your market opportunities
              </div>
              <div className="sub-heading">
                Which applications can you offer with your core abilities? Which
                customers may need them? Zoom in to further segment each
                customer group.
              </div>
            </div>
          </div>
          <div className="opportunities-section__wrapper">
            <div className="opportunities-section">
              <div
                className="row row-without-margin"
                ref={this.opportunityElement}
              >
                {opportunitySections}
              </div>
            </div>
            <div className="adder">
              <TooltipItem
                type="add-section"
                onClick={() => this.handleAddSection()}
                typeId="opportunities"
                readonly={readonly}
              />
              <div className="counter">
                {sectionCount} / {MAX_SECTIONS}
              </div>
            </div>
          </div>
          <div className="opportunity-equation">
            <span className="application part">
              <img src="/assets/icons/box.png" alt="box" />
              <span>application</span>
              <span>+</span>
            </span>
            <span className="customer part">
              <img src="/assets/icons/customer.png" alt="customer" />
              <span>customer</span>
              <span>=</span>
            </span>
            <span className="opportunity part">
              <img src="/assets/icons/box-icon.png" alt="box-icon" />
              <span>market opportunity</span>
            </span>
          </div>
        </div>
      </WorksheetPage>
    );
  }
}

const mapState = (state) => ({
  customerSegments: getProjectCustomerSegments(state),
  sectionCount: getSectionCount(state),
  updateStatus: getUpdateStatus(state),
  projectData: getProjectData(state),
  abilities: getAbilities(state),
  abilitiesSectionCount: getAbilitiesSectionCount(state),
  applications: getApplications(state),
  currentProject: getProjectData(state),
  user: getUserData(state),
  opportunities: getAllSetOpportunities(state),
});

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) =>
    dispatch(setAndSaveProjectData(name, value)),
  onCreateAndUpdateSegmentData: (segments) =>
    dispatch(createAndUpdateSegmentData(segments)),
  onSetUpdateStatus: (status) => dispatch(setUpdateStatus(status)),
  onUpdateChatSessionField: (payload) =>
    dispatch(updateChatSessionField(payload)),
});

export default connect(mapState, mapDispatch)(WorksheetPage1);
