// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Progress, Row } from 'reactstrap';
import { clearExportData, fetchAllProjects, fetchProjectsCount } from '../../../store/project/projectActions';
import { getExportData, getProjectsCount } from '../../../store/project/projectSelectors';
import BasePage from '../BasePage';
import './DownloadProjects.scss';

class DownloadProjects extends Component {
    state = {
        progress: 0,
        downloadUrl: null,
        loading: false,
    };

    controller = new AbortController();
    confirmationInProgress = false;

    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", this.handleBackButton);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.handleBackButton);
        this.setState({ loading: false, progress: 0, downloadUrl: null });
        const { onClearExportData } = this.props;
        this.controller.abort();
        onClearExportData();
    }

    componentDidUpdate(prevProps) {
        const { exportData } = this.props;

        if (prevProps.exportData !== exportData) {
            if (exportData?.status === "completed") {
                this.setState({ downloadUrl: exportData?.csvUrl, loading: false });
                this.props.onClearExportData();
            } else if (exportData?.status === "in-progress") {
                this.setState({ progress: exportData?.progress });
            } else if (exportData?.status === "error") {
                console.error("Error:", exportData?.message);
                this.setState({ loading: false });
            }
        }
    }

    handleBackButton = () => {
        const { loading } = this.state;
        const { onClearExportData } = this.props;
        const abortMsg = process.env.REACT_APP_CSV_ABORT_CONFIRMATION_MSG || "";

        if (this.confirmationInProgress) {
            return;
        }

        if (!loading) {
            return window.history.back();
        }

        this.confirmationInProgress = true;

        if (window.confirm(abortMsg)) {
            this.controller.abort();
            onClearExportData();
            window.history.back();
        } else {
            window.history.pushState(null, document.title, window.location.href);
        }

        this.confirmationInProgress = false;
    };

    fetchCsvFile = async () => {
        const { onFetchAllProjects, onFetchProjectCount, onClearExportData } = this.props;

        this.setState({ loading: true, progress: 0, downloadUrl: null });

        const requestId = Date.now();
        const batchSize = process.env.REACT_APP_CSV_BATCH_SIZE_PER_REQUEST || 500;

        await onFetchProjectCount();
        const { projectsCount } = this.props;
        const totalPages = isNaN(projectsCount?.total) ? 0 : Math.ceil(projectsCount?.total / batchSize);

        for (let page = 1; page <= totalPages + 1; page++) {
            if (this.controller.signal.aborted) {
                break;
            }

            if (page <= totalPages) {
                await onFetchAllProjects(page, batchSize, projectsCount?.total, requestId, this.controller);
            }
        }
    };

    downloadFile = async (downloadUrl) => {
        const errorMessage = process.env.REACT_APP_CSV_NOT_FOUND_MSG || "File not found!";
        try {
            const response = await fetch(downloadUrl, { method: 'HEAD' });

            if (response.ok) {
                window.open(downloadUrl, "_blank");
            } else {
                this.setState({ downloadUrl: null });
                alert(errorMessage);
            }
        } catch (error) {
            console.error(errorMessage, error);
            alert(errorMessage);
        }
    };

    render() {
        const { progress, downloadUrl, loading } = this.state;

        return (
            <div>
                <div id={"tooltip"} />
                <BasePage>
                    <Container className="download-projects">
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <h1>Download Projects CSV</h1>
                                <button
                                    onClick={this.fetchCsvFile}
                                    disabled={loading}
                                    className='mt-4 export-btns'
                                >
                                    {loading ? 'Processing..' : 'Export CSV'}
                                </button>
                                <div className="progress-wrapper">
                                    {loading && (
                                        <Progress value={progress} className="progress-bar-custom">
                                            {progress}%
                                        </Progress>
                                    )}
                                </div>
                                {downloadUrl && (
                                    <button
                                        className="mt-3 export-btns "
                                        onClick={() => this.downloadFile(downloadUrl)}
                                    >
                                        Download CSV
                                    </button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </BasePage>
            </div>
        );
    }
}

const mapState = (state) => ({
    exportData: getExportData(state),
    projectsCount: getProjectsCount(state)
});

const mapDispatch = (dispatch) => ({
    onFetchAllProjects: (page, pageSize, totalProjects, requestId, controller) => dispatch(fetchAllProjects(page, pageSize, totalProjects, requestId, controller)),
    onFetchProjectCount: () => dispatch(fetchProjectsCount()),
    onClearExportData: () => dispatch(clearExportData()),
});

export default withRouter(connect(mapState, mapDispatch)(DownloadProjects));
